.column-wrapper {
	width: 100%;
	max-width: 500px;
}

.bold {
	font-weight: bold;
}
.margin-small {
	margin-bottom: vr(2);
}

.margin-sm {
	margin-bottom: 1rem;
}

.margin-top-med {
	margin-top: 2rem;
}