// variables
$breakpoints: (
	'small'     : 1px,
	'med'       : 700px,
	'large'     : 920px,
	'wide'      : 1200px,
	'mega'      : 1600px,
	);

$typi: (
	base: (
		null: (16px, 1.4),
		med: 18px,
		large: 22px,
		),
	h1: (
		null: ms(2),
		large: ms(3),
		)
	);


// $h1-map: (
// 	null: ms(3),
// 	large: ms(4)
// 	);

// $h2-map: (
// 	null: ms(2),
// 	large: ms(3)
// 	);

// $h3-map: (
// 	null: ms(1),
// 	large: ms(2)
// 	);

// $h4-map: (
// 	null: ms(1),
// 	large: ms(1)
// 	);

@mixin btn-color($color) {
	color: $color;
	border: 1px solid $color;
	&:hover {
		color: darken($color,8%);
		border: 1px solid darken($color,8%);
		transition: all 0.3s ease;
	}
	&:active {
		color: darken($color,25%);
		border: 1px solid darken($color,25%);
	} 
}