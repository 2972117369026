//footer
.footer {
	color: $text;
	text-align: center;
	@include mappy-bp(large) {
		text-align: left;
	}

	> .wrapper {
		padding-top: vr(4);
		padding-bottom: vr(4);
	}

	.grid {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include mappy-bp(large) {
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
		}

		.column + .column {
			@include mappy-bp(small large) {
				margin-top: vr(2);
			}
		}


	}

	a {
		color: $text;
		text-decoration: none;
		transition: color .2s ease;

		&:hover {
			color: darken($text,30%);
		}
	}

	h3 {
		color: $dark;
		text-transform: uppercase;
		font-weight: 800;
		margin-bottom: vr(0.5);
		@include mappy-bp(large) {
			margin-bottom: vr(1);
		}
	}

	.copyright {
		background: $dark;
		color: #fff;
		font-size: 10px;
		padding-top: 10px;
		padding-bottom: 10px;

		> .wrapper {
			display: flex;
			@include mappy-bp(small med) {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
			justify-content: space-between;
		}

		> .wrapper > div {
			width: 50%;
		}

	}
}

.footer h3 {
	font-size: 18px;
}
.footer a {
	line-height: 25px;
}
// .footer-nav {
// 	.item + .item {
// 		margin-top: 5px;
// 	}
// }