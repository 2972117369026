// doctor list

#carousel-2 {
	.item img {
		width: 125px!important; // pra foto dos medicos não ocupar 100% do espaço
	}
}

.doctor-card {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	text-align: center;
	padding: vr(1.5) 15px;
	border: 1px solid #d7d7d7;
	background: #fff;

	> .image {
		border-radius: 50%;
		width: 102px;
		margin-bottom: vr(1);
		box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.50);
	}

	> h3 {
		color: $dark;
		font-size: 16px;
		text-transform: uppercase;
	}

	> .description {
		color: #747474;
	}
}

.corpo-clinico-section {
	padding: vr(3) 0;
	
	.two-column {
		margin-bottom: vr(2);
		@include mappy-bp(med) {
		> .section-title {margin-bottom: 0;}
		}
	}
}

.live-search-box {
	padding: 0.78em;
	display: block;
	width: 100%;
	max-width: 400px;
	// border: 1px solid #3498db;
	border: 1px solid $dark;
	border-radius: 5px;
}

@supports (display: grid) {

	.doctor-grid {
		display: grid;
		grid-gap: 1.2vw;
		grid-template-columns: repeat(auto-fill, minmax(189px, 1fr));
		justify-content: space-between;
	}

// @supports
}

@supports not (display: grid) {

	.doctor-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@include mappy-bp(0px 480px) {
			flex-direction: column;
		}

		&:after {
			content: "";
			flex: auto;
		}

		.item {
			@include mappy-bp(small med) {
				margin-bottom: 20px;
				min-width: 195px;
			}
			@include mappy-bp(med) {
				flex: 0 200px;
				margin-bottom: 50px;
				margin-left: 20px;

				&:nth-child(5n+1) {
					margin-left: 0;
				}
				
			}
		}
	}
// @supports
}