// banner

.banner-section {
	// @include mappy-bp(small large) {
	// margin-bottom: vr(2); 
	// }

	margin-top: -25px; // isso é pra escondero fundo branco que fica quando o menu diminui o padding
	@include mappy-bp(small med) {
		margin-top: 0;
	}
	
	.wrapper {
		// text-align: center;
		// display: flex;
		// flex-direction: column;
		// align-items: center;
		// justify-content: center;
		width: 100%;
		&.-start {
			align-items: flex-start;
			text-align: left;
		}
		&.-end {
			align-items: flex-end;
			text-align: right;
		}
		&.-text-left {
			text-align: left;
		}
	}
	.wrapper > img {
		margin-bottom: vr(1);
		max-width: 100%;
	}
	.text {
		color: #fff;
		font-size: 40px;
		// font-size: 30px;
		font-weight: 800;
	}
}

.banner {
	height: 330px;
	width: 100%;
	@include mappy-bp(med) {
		height: 360px;
	}
	@include mappy-bp(wide) {
		height: 525px;
	}
	@include mappy-bp(1580px) {
		height: 30vw; // usei 35vw antes pra não cortar o banner do cartao amigo
	}

	display: flex; // alinha o texto no meio do banner
	align-items: center; // alinha o texto no meio do banner
}

.cartao-amigo-banner {
	position: relative;

	// @include mappy-bp(large) {
	&:after {
	content: '';
	position: absolute;
	width: 100%;
	height: 444px;
	background: url('../images/cartoes.png') center center no-repeat;
	bottom: -110px;
	@include mappy-bp(large) {
		width: 587px;
		left: 35vw;
	}
	}
	// }
}