.form {
	label {
		display: none;
	}

	textarea {
		font-size: 16px;
	}
	
	textarea,
	input {
		background: transparent;
		border: 1px solid #fff;
		padding: 12px 13px;
		color: #fff;

		// colocar isso no form cartao
		width: 100%;
		// max-width: 425px;
		margin-bottom: 25px;
	}

	textarea::placeholder,
	input::placeholder {
		text-transform: uppercase;
		color: #fff;
	}

	.button {
		cursor: pointer;
		border: none;
		background: #fff;
		color: $light;
		text-transform: uppercase;
		font-weight: 700;
		padding: 15px 35px 12px;
		border: 1px solid #fff;
		transition: all .3s ease;

		&:hover {
			background: transparent;
			border: 1px solid #fff;
			color: #fff;
		}
		&:active {
			color: darken($light,25%);
			background: 1px solid darken(#fff,25%);
		} 
	}
}

// formulario da pagina cartao amigo
.formulario-cartao {
	height: 701px;
	// padding-top: 185px;
	color: #fff;
	@include mappy-bp(1400px) {
		// padding-top: 455px;
		// padding-top: 28vw;
		height: 50vw;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: inherit !important;
	}

	.title {
		margin-top: vr(3);
		margin-bottom: vr(1);
		font-size: 20px;
	}


}

#form-contato {
	max-width: 1136px;
}

#form-cartao {
	max-width: 433px;
	display: flex;
	flex-flow: row wrap;

	> .form-field {
		flex: 1 1 100%;

		&:nth-child(4) {
			flex: 1 1 calc(25% - 20px);
			@include mappy-bp(med) {
				flex: 1 1 calc(20% - 20px);
			}
			margin-right: 20px;
		}
		&:nth-child(5) {
			flex: 1 1 75%;
			@include mappy-bp(med) {
				flex: 1 1 80%;
			}
		}
	}

	@include mappy-bp(1800px) {
		textarea {
			height: 170px;
		}
	}
}


.form-contato,
#form-contato {
	// background:red;
	@include mappy-bp(small med) {
		text-align: center;
	}
	@include mappy-bp(med) {
		display: flex;
		flex-flow: row wrap;
	}
	
	@include mappy-bp(med) {
		.form-field {
			flex: 1 1 100%;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				flex: 1 1 calc(33.33% - 20px);
				margin-right: 20px;
			}
			&:nth-child(3) {
				margin-right: 0;
			}

		}
		textarea {
			max-width: none;
		}
	}
	// end media-querie
	textarea,
	input {
		border: 1px solid $text;
		color: $text;
	}
	textarea {
		height: 167px;
	}


	textarea::placeholder,
	input::placeholder {
		text-transform: uppercase;
		color: $text;
	}

	.button {
		background: $dark;
		color: #fff;
		border: 1px solid $dark;
		transition: all .3s ease;

		&:hover {
			background: transparent;
			border: 1px solid $dark;
			color: $dark;
		}
		&:active {
			color: darken($light,25%);
			background: 1px solid darken(#fff,25%);
		} 
	}
}
.section-formulario-contato {
	margin-bottom: 4rem;
}
// formulario rh
.section-formulario-rh {
	color: #fff;
	background: url('../images/backgrounds/bg-formulario-rh.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	padding-bottom: 40px;
	height: auto;
	@include mappy-bp(wide) {
		background-position: 90% center;
	}
	@include mappy-bp(1500px) {
		// height: 30vw;
		height: 38vw;
	}

	.wrapper {
		padding-top: 90px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: inherit;
	}

	.column-wrapper {
		max-width: 625px
	}

	.section-title {
		margin-bottom: vr(1);
	}

	.text {margin-bottom: vr(1);}

}

#form-trabalho {
	// max-width: 645px;
	display: block;
	label {
		display: block;
	}

	@include mappy-bp(med) {
		display: flex;
		flex-flow: row wrap;
	}
	
	> .buttons,
	> .form-field {
		@include mappy-bp(med) {
			flex: 1 1 50%;
			
		// a child (3) é o honeypot
		&:nth-child(1),
		&:nth-child(4) {
			flex: 1 1 calc(50% - 20px);
			margin-right: 20px;
		}
	}
}

	.no-margin {
		margin-right: 0 !important;
	}
}

#form-trabalho .dropzone {
	background: transparent !important;
}

#form-trabalho .form-input-file.dropzone {
	color: #fff !important;
	border: 1px solid #fff !important;
	display: inline-block;
	width: 100%;
	// padding: 0.3rem !important;
}

.termos-privacidade {
	@include mappy-bp(med) {
		flex: 1 1 100% !important;
	}

	.input-termos-privacidade {
		width: initial;
	}
	
	a {
		color: #fff;
	}
}

.termos-privacidade.aprendiz {
	a {
		color: #1d5b2d;
	}
}