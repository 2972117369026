// cartao amigo
.cartao-amigo {
	color: #fff;
	// background-position: center left;
	// background-repeat: no-repeat;
	@include mappy-bp(med) {
		background-position: center center !important;
	}

	.title {
		font-weight: 800;
		text-transform: uppercase;
		margin-bottom: vr(1);
		font-size: 40px;
	}

	.text {
		margin-bottom: vr(2);
		font-weight: 300;
		font-size: 19px;
	}
	.text-wrapper {
		@include mappy-bp(large) {
			width: 50%;
		}
		padding: vr(3.8) 0;
	}
}