// base
*, 
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.wrapper {
	max-width: 90%;
	padding: 0 10px;
	@include mappy-bp(480px) {
		padding: 0 20px;
	}
	@include mappy-bp(wide) {
		max-width: 1460px;
	}
	margin: 0 auto;
}

.padding {
	padding-top: vr(1);
	padding-bottom: vr(1);
}

.margin {
	margin-bottom: vr(5);
}

p + p {
	margin-top: vr(1);
}

.btn {
	text-transform: uppercase;
	text-decoration: none;
	padding: 5px 35px;
	width: 150px;
	border-radius: 20px;
	font-size: 14px;
}

.btn-white {
	@include btn-color(#fff);
	    &:hover {
    	background: #fff;
    	color: $dark;
    }
}

.btn-green {
	@include btn-color($dark);
    &:hover {
    	background: $dark;
    	color: #fff;
    }
}

.two-column {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;
}

.align-right {
	text-align: right;
	justify-content: flex-end;
	align-items: flex-end;
}

.columns {
	display: block;
	@include mappy-bp(med) {
	display: flex;
	}
	// justify-content: space-between;
	// flex-wrap: wrap;

// 	> .column {
// 		@include mappy-bp(large) {
// 			width: 50%;
// 		}
// 	}
}

.space-between {
	justify-content: space-between;
}

.text-right {
	@include mappy-bp(med) {
	text-align: right;
	}
}

// .text-block {
// 	max-width: 980px;
// 	margin: 0 auto;
// }

html {
	height: 100%;
	@include typi-init;
	// color: $dark_color;
}


h1 {
	@include typi('h1');
}

ul {
	list-style: none;
}

// sticky footer
body {
	min-height: 100%;
	font-family: 'Open Sans', sans-serif;
	display: flex;
	flex-direction: column;
	color: $text;
	// overflow-x: hidden //adicionei isso por causa dos pngs que tem nos banners
}

.content {
	flex: 1;
	// padding-top: vr(1);
	// margin-bottom: vr(1);
}

.section-title {
	font-weight: 800;
	text-transform: uppercase;
	color: $dark;
	border-left: 4px solid $dark;
	padding-left: 10px;
	margin-bottom: vr(2);
	&.-white {
		color: #fff;
		border-left: 4px solid #fff;
	}
}