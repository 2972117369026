.header {
	height: 155px;
	// box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.36);
	z-index: 100;
	// > .wrapper {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	position: relative;
	// 	align-items: center;
	// }
}

.logo img {
	width: 260px;
	transition: width 0.1s ease;
	@include mappy-bp(large wide) {
		width: 160px;
	}
}

.topbar {
	background: $light;
	color: #fff;
	text-align: center;
	z-index: 11;
	.arrow {
		fill: #fff;
	}

	.toggle-topbar {
		@include mappy-bp(large) {
			display: none;
		}
	}
}

.topbar-nav {
	.link {
		position: relative;
		display: inline-flex;
	}
	
	.icon-1:before,
	.icon-2:before,
	.icon-3:before {
		content: '';
		display: block;
		position: absolute;
		left: -13px;
	}
	.icon-1:before {
		width: 9px;
		height: 6px;
		background: url('../images/icons/icone-carta.png') center center no-repeat;
		top: 35%;
		// transform: translateY(50%);
	}

	.icon-2:before {
		width: 8px;
		height: 7px;
		background: url('../images/icons/icone-fone.png') center center no-repeat;
		top: 35%;
		// transform: translateY(50%);
	}

	.icon-3:before {
		width: 8px;
		height: 7px;
		background: url('../images/icons/icone-busca.png') center center no-repeat;
		top: 35%;
		left: -15px;
		@include mappy-bp(med) {
			left: 0;
		}
		// transform: translateY(50%);
	}

}

.topbar-nav {
	display: flex;

	@include mappy-bp(small large) {
		flex-direction: column;
		text-align: left;
		align-items: center;
		padding: 10px 0;
		margin-bottom: 15px;
		transition: all .2s ease;
		margin-top: -115px;
	}
	@include mappy-bp (large) {
		justify-content: flex-end;
		padding: 8px 0;
	}

	&.-active {
		margin-top: 0;
	}

	.item {
		padding-left: 30px;

		&:last-child {
			padding-left: 15px;
		}
	}

	.link {
		color: #fff;
		text-decoration: none;
		font-size: 14px;
		font-weight: 700;
	}
}

// nav

.navbar {
	background: #fff;
	width: 100%;
	box-shadow: 0px 5px 11px 0px rgba(0,0,0,0.36);
	z-index: 10;
	transition: position 10s;
	padding: 1.4rem 0;
	// position: relative;
	transition: all .2s ease;
	a {
		text-decoration: none;
		color: #b6b6b6;
		// color: $text;
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 600;
		
		@include mappy-bp(large) {
			&:hover {
				color: darken($text,30%);
			}
		}
	}

	.toggle-nav {
		display: inline-flex;
		@include mappy-bp(large) {
			display: none;
		}
	}
	.open-nav {
		align-items: center;
		justify-content: center;
	}
	.close-nav {
		justify-content: flex-end;
		padding: 20px;
		margin-bottom: vr(1);
	}

	> .wrapper {
		display: flex;
		justify-content: space-between;
		position: relative;
		align-items: center;
	}
}

.fixed-nav {
	position: fixed !important;
	z-index: 999;
	left: 0;
	top: 0;
	width: 100%;
	// box-shadow: 0px 1px 16px 0px rgba(0,0,0,0.36);
	padding: 10px 0;

	.logo img {
		width: 240px;
	}
}

.main-nav {
	display: flex;
	transition: all .3s ease;
	@include mappy-bp(small large) {
		flex-direction: column;
		text-align: center;
		position: fixed;
		top: 160px;
		top: 0;
		right: 0;
		width: 100vw;
		width: 100%;
		height: 100%;
		transform: translateY(-100%);
		visibility: hidden;
		opacity: 0;

		& .item {
			margin-bottom: vr(2);
		}

		.item .link {
			vertical-align: middle;
		}
	}
	@include mappy-bp(large wide) {
		a {
			font-size: 14px;
		}
	}
	@include mappy-bp(large) {
		& > .item {
			display: inline-flex;
			padding: 0 15px;
			border-right: 1px solid #b6b6b6;

			&:last-child {
				border-right: none;
				padding-right: 0;
			}
		}
	}

	&.-active {
		transform: translateY(0);
		padding: 5px 15px;
		background: rgb(74,166,41);
		visibility: visible;
		opacity: 1;
		overflow-y: scroll;

		a {
			color: #fff;
		}
	}

	.has-children > ul a {
		font-size: 14px;
		@include mappy-bp(large) {
			font-size: 16px;
		}
	}

	.has-children > ul li {
		@include mappy-bp(large) {
		padding-bottom: 14px;
		}
	}
	
	// submenu telas grande
	.has-children {
		position: relative;
	}

	.has-children > ul {
		visibility: hidden;
		@include mappy-bp(small large) {
		opacity: 0;
		max-height: 0;
		}
		@include mappy-bp(large) {
		max-width: 170px;
		padding: 16px;
		position: absolute;
		top: 20px;
		left: 0;
		background: #fff;
		box-shadow: 0px 10px 16px 0px rgba(32, 24, 24, 0.10);
		}
		transition: all 0.2s ease-in-out;
	}

	.has-children > ul.-active {
		visibility: visible;
		opacity: 1;
		@include mappy-bp(small large) {
		max-height: 500px;
		margin-top: 2rem;
		}
	}

	.has-children ul li:last-child {
		margin-bottom: 0;
	}

	.selected > a {
		color: $dark;
		@include mappy-bp(large) {
		color: $light;
		}
	}

}

.search-wrapper .search-input {
	@include mappy-bp(small med) {
	width: 100% !important;
	}
}