//cards
.card-section {
	position: relative;
	margin-top: -160px;
	@include mappy-bp(small med) {
		margin-top: -50px;
	}
	// z-index: 99;
	z-index: 10;
}
.card-list {
	display: flex;
	justify-content: center;
	flex-direction: column;

	@include mappy-bp(med) {
		flex-direction: row;
	}
}

.card-item {
	background: $light;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding: vr(1.5) 10px;
	@include mappy-bp(large) {
	flex-grow: 1;
	flex-basis: 0;
	}
	@include mappy-bp(med) {
	&:nth-child(n + 2) {
		
		// box-shadow: -5px 0px 8px -2px rgba(0,0,0,0.75);
		box-shadow: -10px 0px 8px -2px rgba(0,0,0,0.30);
		}
	}

	&.-dark {
		background: $dark;
	}

> .icon {
	width: 60px;
	margin-bottom: vr(1);
}

.icon-2 {
	width: 32px;
	position: relative;
}

a {
	position: relative;
}
a::after {
	display: block;
	content: 'Saiba Mais';
	width: 80px;
	position: absolute;
	left: -20px;
	color: #fff;
	transform: translateY(-30px);
	opacity: 0;
	transition: all .2s ease;
	z-index: 4;
}

&:hover a::after {
	z-index: 3;
	transform: translateY(0);
	opacity: 1;
}

// a:hover::after {
// 	// color: $text;
// }
> .title {
	text-transform: uppercase;
	font-weight: 800;
	margin-bottom: vr(0.5);
}

> .description {
	font-weight: 400;
	margin-bottom: vr(1);
}
}