// links section
.links-section {

	// estilos da lista
	> .link-list {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		@include mappy-bp(med) {
			flex-direction: row;
		}
	}
	
	// images de background
	> .link-list > .item-1 > .background {background: url('../images/box/estrutura.jpg') center center no-repeat}
	> .link-list > .item-2 > .background {background: url('../images/box/jornal.jpg') center center no-repeat}
	> .link-list > .item-3 > .background {background: url('../images/box/especialidades.jpg') center center no-repeat}

	// estilo do background
	> .link-list > .item > .background {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: filter .2s ease, color 3s ease;
		background-size: cover;
	}
	
	> .link-list > .item > .icon {
		opacity: 0;
		transition: opacity .3s ease;
		transition-delay: 150ms;
		margin-bottom: vr(0.5);
	}
	// estilo do texto
	> .link-list > .item > span {
		transition: opacity .3s ease;
		transition-delay: 100ms;
		text-transform: uppercase;
		font-weight: 800;
		font-size: 21px;
		// margin-bottom: 100px;
		opacity: 0;
		// visibility: hidden;
	}

	// estilos gerais do box
	> .link-list > .item {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		flex-direction: column;
		position: relative;
		color: #fff;
		width: 100%;
		height: 370px;

		@include mappy-bp(med) {
			width: 33.33%;
		}

		// efeito hover bg
		&:hover .background {
			filter: brightness(35%);
		}
		// efeito hover texto
		&:hover > span {
			// margin-bottom: 0;
			// visibility: visible;
			opacity: 1;
		}	
		&:hover > .icon {
			opacity: 1;
		}	
	}


}

// lista dados
.data-section {
	background: url('../images/backgrounds/bg-data.jpg') center center no-repeat;
	background-size: cover;
}
.data-list {
	display: flex;
	justify-content: space-around;
	color: #fff;
	padding: vr(3.5) 0;
	text-align: center;
	@include mappy-bp(small 410px) {
		flex-direction: column;
	}

	.item > span {
		display: block;
		&:first-child {
			font-size: 32px;
			font-weight: 800;
		}
		&:nth-child(2) {
			font-size: 17px;
			font-weight: 300;
		}
	}
}