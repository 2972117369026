.pagination {
	margin: vr(1) 0 vr(3.5) !important;
	a 
	{
		text-decoration: none;
	}
	
	li {
		border: 1px solid $dark !important;
	}

	a,
	li {
		color: $dark;
	}

	a,
	span {
		padding: 6px 15px !important;
	}

	span {
		color: #fff;
		background: $dark;
	}

}
// noticias da home
.recent-posts-list {
	> .post-item {
		padding-bottom: vr(2);

		@include mappy-bp(small med) {
			margin-bottom: vr(2);
		}
		@include mappy-bp (med) {
			width: 50%;
		}
		@include mappy-bp (wide) {
			width: 25%;
		}
		&:hover a .text .summary {
			color: darken($text,30%);
		}
		&:hover a .text .title {
			color: $dark;
		}
		&:hover a .image {
			filter: brightness(0.8);
		}
		a {
			text-decoration: none;
		}
		a > .image {
			height: 200px;
			margin-bottom: vr(2);
			transition: filter .3s ease;
		}

		a > .text {
			color: $text;
			@include mappy-bp(med) {
				padding: 0 15px;
			}
		}
		a > .text > .title {
			text-transform: uppercase;
			font-weight: 800;
			margin-bottom: vr(1);
			transition: color .3s ease;

		}
		a > .text > .summary {
			transition: color .3s ease;
		}
	}


	.post-item:nth-child(2n+1) {
		border-bottom: 3px solid $dark;
	}
	.post-item:nth-child(2n+2) {
		border-bottom: 3px solid $light;
	}
// ajustes de margem nos items do blog
@include mappy-bp(med wide) {
	.post-item:nth-child(-n+2) {
		margin-bottom: vr(2);
	}
	.post-item:nth-child(2n+2) {
		width: calc(50% - 10px);
		margin-left: 10px;
	}
}

@include mappy-bp(wide) {
	.post-item:nth-last-child(-n+3) {
		width: calc(25% - 5px);
		margin-left: 5px;
	}
}
}

.recent-posts-list {
	display: flex;
	justify-content: center;
	@include mappy-bp(large) {
		justify-content: space-between;
	}
	flex-wrap: wrap;
}


// lista da pagina noticias
.post-list {
	@supports (display: grid) {
		display: grid;
		// grid-gap: 5vw;
		grid-column-gap: 5vw;
		grid-row-gap: 5vw;
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		@include mappy-bp(large) {
			grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
		}
		justify-content: space-between;
		// @supports
	}

	@supports not (display: grid) {
		display: block;
		@include mappy-bp(large) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		}

		> .post-item {
			margin-bottom: vr(3);
		}
	}

	@include mappy-bp(wide) {
		justify-content: space-between;
	}

	h1 {font-size: 26px;}

	> .post-item {
		// border: 1px solid red;
		height: auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
	// margin-bottom: 40px;
		// border: 1px solid tomato;
		@include mappy-bp(large) {
		justify-content: flex-start; // pra manter alinhado ao layout do lado esquerdo
		// flex: 1 1 50%; // isso faz os box ficarem em duas linhas em tela media-grande, mas buga o texto
	}

}
// > .post-item .image,
> .post-item .title,
> .post-item .summary {
	margin-bottom: vr(1.5);
}
> .post-item .text {
	height: 100%; //isso faz os botões ficarem alinhados, mas como todos os posts vão ter textos é desnecessario pq buga em alguns tamanhos de tela
	width: 220px;
	position: relative;
}

.post-item .btn {
	display: inline-flex;
	// position: absolute;
	// left: 10%;
	// bottom: 0;
}

> .post-item .title {
	font-size: 20px;
	font-weight: 800;
}

> .post-item a {
	text-decoration: none;
}

> .post-item:hover .image {
	filter: grayscale(60%);
}
> .post-item .image {
	object-fit: cover;
	transition: filter .3s ease;
	@include mappy-bp(549px) {
		margin-right: 15px;
		// &:hover {
		// 	filter: grayscale(60%);
		// }
	}
}

> .post-item .link {
	color: $dark;

	&:hover {
		color: saturate($dark, 45%);
	}
}
}


// pagina interna do post
.blog-article {
	max-width: 740px;
	margin: 0 auto;
	margin-bottom: vr(3.5);
	
	.post-author {
		font-weight: 700;
	}
	.post-title {
		margin-top: vr(3);
		margin-bottom: vr(1.5);
		color: $dark;
		font-weight: 800;
	}
	// .post-title a {
	// 	color: $dark;
	// 	text-decoration: none;
	// }

	.post-date {
		display: inline-block;
		margin-bottom: vr(1);
	}
	
	.post-buttons {
		display: flex;
		justify-content: space-between;
	}
	.social-links a:first-child {
		margin-right: 10px;
	}
	.social-links a {
		text-decoration: none;
	}

	.btn {
		color: #fff;
		background: $dark;
		border-radius: 0;
		transition: background .2s ease;
		font-weight: 700;

		&:hover {
			background: darken($dark,8%);
		}
	}
}

.blog-content {
	h2, h3, h4 {
		margin: vr(1) 0;
	}
	img {
		max-width: 740px;

	}
	
	.header-image {
		border-bottom: 1px solid #dadada;
		padding-bottom: 15px;
		margin-bottom: vr(2);
	}

	.legenda-foto {
		position: relative;
		padding-left: 25px;
		font-size: 14px;
		font-weight: 300;
		margin-top: 5px;
		&:before {
			content: '';
			background: url('../images/icons/camera-icon.png') center center no-repeat;
			position: absolute;
			left: 0;
			top: 16%;
			width: 16px;
			height: 12px;
		}
	}
	.image {
		// width: 100%; // ativar isso se eu usar bg image no post
		// height: 360px; // ativar isso se eu usar bg image no post
		width: 100%;
		max-width: 100%;
	}

	margin-bottom: vr(3);
}