/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
 .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; 

  margin-top: 0;
  position: absolute;
  top: -75px;
  right: 0;
}
.owl-theme .owl-nav [class*='owl-'] {
  color: #FFF;
  font-size: 14px;
  margin: 5px 2px;
    // padding: 5px 10px;
    width: 24px;
    height: 24px;
    // background: #D6D6D6;
    background: $dark;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px; 
  }

  .owl-theme .owl-nav [class*='owl-']:hover {
    background: darken($dark, 5%);
    color: #FFF;
    text-decoration: none; }
    .owl-theme .owl-nav .disabled {
      opacity: 0.5;
      cursor: default; }

      .owl-theme .owl-nav.disabled + .owl-dots {
        margin-top: 10px; }

        .owl-theme .owl-dots {
          text-align: center;
          -webkit-tap-highlight-color: transparent; }
          .owl-theme .owl-dots .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline; }
            .owl-theme .owl-dots .owl-dot span {
              width: 10px;
              height: 10px;
              margin: 5px 7px;
              background: #D6D6D6;
              display: block;
              -webkit-backface-visibility: visible;
              transition: opacity 200ms ease;
              border-radius: 30px; }
              .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
                background: #869791; }


               //  .owl-stage {
               //   display: flex;
               //   justify-content: space-between;
               //   background:red;
               // }
               // .owl-item {
               //  border: 1px solid blue;
               //  width: 200px;
               //  background:green;
               // // flex: 0 0 auto;
               // }

