// coloca marcador antes das listas
.list {
	li:before {
		content:"• ";
	}   
}

.list-margin {
	li {
		margin-bottom: vr(1);
	}
}

// estilos praquela area de box verdes da pagina Hospital
.info-section {
	@include mappy-bp(med) {
		background: $light;
	}

	.box-list {
		color: #fff;
		display: block;
		@include mappy-bp(med) {
			display: flex;
		}
		
		@include mappy-bp(med){
			.item:nth-child(n +2) {
				box-shadow: -10px 0px 8px -2px rgba(0,0,0,0.30);
			}
		}
		
		@include mappy-bp(small med) {
			.box-1,
			.box-3 {
				background: $light;
			}
		}
		
	}
}

.box-list {
	> .item {
		@include mappy-bp(med) {
			width: 33.33%;
		}
		padding: 40px;
		@include mappy-bp(med) {
			&:first-child {
				padding-left: 0;
			}
		}
	}

	> .item > .title {
		margin-bottom: vr(1);
		text-transform: uppercase;
	}

	> .item > p {
		line-height: 2;
	}
	
	> .box-1 > .title:before {
		content: '';
		display: inline-block;
		background: url('../images/icons/icone-acompanhante.png') center center no-repeat;
		background-size: cover;
		width: 43px;
		height: 43px;
		// float: left;
		margin-right: 10px;
	}
	> .box-2 > .title:before {
		content: '';
		display: inline-block;
		background: url('../images/icons/icone-telefone.png') center center no-repeat;
		background-size: cover;
		width: 39px;
		height: 40px;
		// float: left;
		margin-right: 10px;
	}
	> .box-3 > .title:before {
		content: '';
		display: inline-block;
		background: url('../images/icons/icone-pastoral.png') center center no-repeat;
		background-size: cover;
		width: 41px;
		height: 40px;
		// float: left;
		margin-right: 10px;
	}
	> .box-2 {
		background: $dark;
	}
}

// // não teve motivos pra usar grid aqui pois só precisei ajustar lateralmente (era mais simples)
// @supports (display: grid) {
// 	.lista-especialidades {
// 		display: grid;
// 		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

// 		li:before {
// 			content:"• ";
// 		} 

// 		li {
// 			margin-bottom: 20px;
// 		}
// 	}
// // @supports
// }

// @supports not (display: grid) {
	.lista-especialidades {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		li {
			margin-bottom: vr(1);
			min-width: 230px;
		}
		li:before {
			content:"• ";
		}   
	}
	
	.lista-colunas {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		h4 {
			margin-bottom: vr(1);
		}

		.column {
			margin-bottom: vr(1);
			flex: 1 1 0;
			min-width: 230px;
			line-height: 1.6;
			padding: 0 20px;
		}
		li:before {
			content:"• ";
		}   
	}

.file-list li {
	margin-top: 1rem;
}
// }