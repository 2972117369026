@import "../bower_components/mappy-breakpoints/mappy-breakpoints";
@import "../bower_components/typi/scss/typi";
@import "../bower_components/modular-scale/stylesheets/modularscale";
@import 'colors';
@import "lightbox";
@import "aos";
@import 'carousel/owl.carousel';
@import 'carousel/owl.theme.default';

// @import 'utilities/all';
// @import 'grid/all';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800,800i');
@import 'variables';


@import 'partials/base';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/banner';
@import 'partials/cards';
@import 'partials/lists';
@import 'partials/forms';

@import 'partials/home';
@import 'partials/blog';
@import 'partials/cartao-amigo';
@import 'partials/medicos';

@import 'partials/general';


// @import 'partials/slider';
// @import 'partials/cards';
// @import 'partials/footer';
// @import 'partials/events';
// @import 'loja';


#preloader {
	position: fixed;
	left: 0;
	top: 0;
	z-index:
	999;
	width: 100%;
	height: 100%;
	overflow: visible;
	// background: #333 url('http://files.mimoymima.com/images/loading.gif') no-repeat center center;
	background: #fff url('../images/icons/rolling.svg') no-repeat center center;
}

// estilo da imagem na pagina o hospital
.estrutura-section {
	img {width: 100%;}
}



//cartao amigo, margem negativa pra ficar mais proximo do banner q tem 'img saindo pra fora do box'
// .section-vantagens-cartao {
// 	// margin-top: -75px;
// }

//lista da pagina serviços
.section-lista-servicos {
	padding: vr(3) 0;
	background:#f1f1f1;
}

// .column {
// 	border: 1px solid blue;
// }

// box com 4 items na page cartao amigo posso mudar a classe pra algo mais geral

.icon-grid {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;

	.item {
		border: 1px solid $dark;
		padding: 8px;
		// width: 50%;
		// flex: 0 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.item > .text {
		color: $dark;
		font-weight: 800;
		text-transform: uppercase;
		font-size: 13px;
	}
}

// seção que fica na página Cartão Amigo
.section-vantagens-cartao {
	.lista-vantagens {
		li {
			margin-bottom: vr(1);
		}
	}
	.columns {
		justify-content: space-between;

	}
	.columns > .column:first-child {
		flex: 1;
	}

	.icon-grid {
		width: 220px;
		height: 200px;
		justify-content: space-between;
		@include mappy-bp(small med) {
			margin: 0 auto;
		}
	}
	.icon-grid > .item {
		width: 100px;
		height: 100px;
		margin-bottom: 20px;
	}
}

.descricao-servicos {
	.title {
		color: $dark;
		margin-bottom: vr(1);
	}
}
#carousel-servicos {
	max-width: 500px;

	.owl-nav {
		top: 46%;
		// border: 1px solid blue;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

#banner-carousel {
		.owl-nav {
		top: 50%;
		// border: 1px solid blue;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.owl-prev {
		padding-left: 40px;
	}
	.owl-next {
		padding-right: 40px;
	}
	.owl-prev,
	.owl-next {
		background:none;
	}
}

#map {
	height: 295px;
}

.section-horarios-de-visita {
	@include mappy-bp(large) {
		background: url('../images/backgrounds/horarios-bg.jpg') center center no-repeat;
		background-size: cover;
	}
	padding-bottom: vr(5);
	
	ul {
		@extend .list;
	}

	ul p {
		display: inline;
	}

	ul li + li {
		margin-top: vr(1);
	}

	.column-wrapper {
		max-width: 700px;
	}
}

.barra-frase {
	color: #fff;
	padding: 90px 0;
	font-size: 32px;
	text-align: center;
	font-style: italic;
	font-weight: 800;
}

.section-lista-convenios {
	.columns {
		justify-content: space-between;
	}

	ul {
		@extend .list;
		font-weight: bold;
	}

	ul,
	.convenios-lista {
		max-width: 400px;
	}

	ul li + li {
		margin-top: vr(1);
	}
}

.box-aviso {
	border: 1px solid $text;
	padding: 8px;
	position: relative;
	border-radius: 12px;
	margin-top: vr(2);

	.title {
		position: absolute;
		border: 1px solid $text;
		top: -20px;
		background:#fff;
		left: 20px;
		border-radius: 20px;
		padding: 5px 10px;
	}

	.text {
		padding: 10px 5px 5px;
	}
}

.search-input {
	background: transparent;
	border: 1px solid #fff;
	padding: 5px 10px;
	color: #fff;

	&::placeholder {
		color: #fff;
	}
}

.descricao-servicos {
	p {
		line-height: 25px;
	}
}
#estrutura-carousel {
	margin-bottom: vr(5);
	// box-shadow: 0px 31px 25px -2px rgba(0,0,0,0.35);
	position: relative;
	@include mappy-bp(large) {
		// &:after {
		// 	position: absolute;
		// 	content: '';
		// 	background: url('../images/shadow.jpg') center center no-repeat;
		// 	background-size: cover;
		// 	width: 100%;
		// 	height: 90px;
		// }
	}
	.item a {
		display: block;
		width: 100%;
		height: 400px;
	}
}

.content-default {
	img {max-width: 100%;}
	h1,h2,h3 {
		color: $dark;
		margin-bottom: vr(1);
	}
}

.left-gradient-box {
	background: linear-gradient(to right, #999 0, #ffffff 7px, #ffffff 100%);
}


//  =============================================== 
//    Borders pra ajudar a debugar o código
// ==========================================
// .columns {
// 	border: 3px solid #00FF00;
// }
// .column {
// 	border: 1px solid blue;
// }

#icon-4,
#icon-3,
#icon-2,
#icon-1 {
/*   #path123 { */
	fill-opacity: 0;
	stroke: #fff;
	stroke-width: 1;
	stroke-dasharray: 425px;
	stroke-dashoffset: 425px;
	animation-name: draw;
	animation-duration: 1.5s;
    animation-fill-mode: forwards; // Stay on the last frame
    animation-iteration-count: 1; // Run only once
    animation-timing-function: linear;
/*   } */
}

#icon-2 {
	stroke-dasharray: 325px;
	stroke-dashoffset: 325px;
}

#icon-3 {
	stroke-dasharray: 325px;
	stroke-dashoffset: 325px;
}

#icon-4 {
	stroke-dasharray: 325px;
	stroke-dashoffset: 325px;
}

@keyframes draw {
	99% {
		stroke-dashoffset: 0;
		fill-opacity: 0;
		stroke-width: 1;
	}
	100% {
		fill-opacity: 1;
		stroke-width: 0;
	}
}



//  =============================================== 
//    Estilos para o berçario
// ==========================================

.lista-bercario {
	margin-top: -60px!important;
}

.bercario-title {
	color: $dark;
	margin-top: 58px;
	margin-bottom: 48px;
	font-size: 26px;
}

.icon-baby::before {
	content: '';
	display: inline-block;
	background: url('../images/iconnes/icone-bebe.png') center center no-repeat;
	height: 38px;
	width: 38px;
	margin-right: 12px;
	vertical-align: middle;
}

.informacoes-bebe {
	.info-title {
		color: $dark;
		font-weight: 700;
		margin-left: 24px;
		}

	p {
		position: relative;
	}

	img {
		position: absolute;
		text-align: center;
		bottom: 3px;
		// transform: translate(Y)
	}
}
.flex-align {
	display: flex;
	justify-content: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
}

.ficha-bebe {
	margin-bottom: 120px;
	.foto {
		margin-right: 24px;
	}

	.foto img {
		box-shadow: 0px 0px 20px 0px rgba(35, 31, 32, 0.7);
		border: 9px solid #fff;
	}
	.informacoes-bebe {
		font-size: 16px;
		font-weight: 700;
	}
}
.coluna-3 {
	align-self: flex-end;
	flex-grow: 1;
	text-align: right;

	img {
		margin-left: 2px;
	}
}

.outros-bebes {
	img {
	opacity: .5;
	transition: opacity .3s ease;
}
.item {
	margin: 0 2px;
}
}

.outros-bebes img:hover {
	opacity: 1;
}

.data-bercario {
	margin-top: 120px;
}

.bercario-search {
	text-align: left;
	margin-bottom: 72px;
	.search-input {
		border-color: $dark !important;
		background: #fff !important;
		color: $dark !important;
		border-radius: 7px !important;
		border-width: 1px;
		max-width: 430px !important;

		&::placeholder {
		color: $dark;
	}
	}
}


@supports (display: grid) {
.outros-bebes-list,
.bercario-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	justify-content: space-between;
	grid-gap: 6px;
}
}

@supports not (display: grid) {
.outros-bebes-list,
.bercario-list {
	display: flex;
	justify-content: space-between;
}
}

.outros-bebes-list,
.bercario-list {
	img {
		width: 100%;
		height: auto;
	}
}

.home-bercario {
	list-style: none;
	border: 1px solid $dark;
	border-radius: 8px;
	padding: 16px 16px;


	.bercario-item {
		display: inline-block;
	}

	.bercario-item + .bercario-item {
		// margin-left: 16px;
		position: relative;
	}

	.bercario-item + .bercario-item:before {
		content: '-';
		position: absolute;
		color: $dark;
		font-weight: 700;
		left: -12px;
		top: 50%;
		transform: translateY(-50%);
	}

	.link {
		text-decoration: none;
		color: $dark;
		font-weight: 600;
		font-size: 16px;
	}
}

.search-submit {
	background: $dark;
	color: #fff;
	font-weight: 700;
}

.bercario-list .search-term {
	display: none !important;
}

.bercario-list .link,
.nome-rn {
	text-decoration: none;
	color: $dark;
}

#parceiros-slide {
	li {
		// width: 25%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 200px;
	}

	li img {
		object-fit: contain;
		max-height: 160px;
	}
}

.anexo label {
	display: block;
	color: #F31431;
}

#form-aprendiz .files-upload {
	width: 100% !important;
	max-width: 485px !important;
}

#form-trabalho .button {
	margin-top: 45px;
}

// aviso corona
.banner-aviso {
	background: #fff;
	border-radius: 4px;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 976px;
	padding: 2rem;
	box-shadow: 0px 0px 33px 0px rgba(0,0,0,0.25);
	margin-top: 80px;
	position: relative;
	z-index: 20;
	@include mappy-bp(med) {
		margin-top: 0;
		padding: 56px 132px;
		position: static;
	}

	.banner-title {
		color: #333;
		@include mappy-bp(med) {
			font-size: 28px;
		}
	}

	.banner-subtitle {
		font-size: 16px;
		color: #898989;
	}

	.banner-number {
		color: #1D5B2D;
		font-size: 28px;
		font-weight: 700;
	}

	.banner-flex {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		text-align: center;
		margin-top: 32px;
	}
}

.portal-transparencia {
	a {
		color:#898989;
	}
	
	a:hover {
		color: #1D5B2D;
	}
}

.ano-relatorio + .ano-relatorio {
	margin-top: 2rem;
}

.ano-relatorio {
	.pdf + .pdf {
		margin-top: 8px;
	}
}


// .banner-section {
// 	position: relative;
// 	z-index: 100;
// }